.app {
  text-align: center;
}

.board {
  position: relative;
  width: 100%;
  max-width: 700px;
}

.board:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  height: 100%;
  width: 100%;
}

.board-row {
  height: 20%;
}

.square {
  max-height: 140px;
  width: 20%;
}

.square-black {
  background-color: black;
}

.square-white {
  background-color: whitesmoke;
}

.square-selected {
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0.5),
    rgba(255, 0, 0, 0.5)
  );
}

.square-valid {
  background-image: linear-gradient(
    to right,
    rgba(0, 255, 0, 0.5),
    rgba(0, 255, 0, 0.5)
  );
}
